/*=========================================================================================
File Name: Home.vue
Description: Home Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- About Area Component -->
  <about-area/>
  <!-- Timeline Area Component -->
  <timeline-area/>
  <!-- Counter Area Component -->
  <counter-area/>
  <!-- Expert Area Component -->
  <expert-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../components/BreadcrumbArea";
import AboutArea from "../components/AboutArea";
import TimelineArea from "../components/TimelineArea";
import CounterArea from "../components/CounterArea";
import ExpertArea from "../components/ExpertArea";
export default {
  name: "About",
  components: {ExpertArea, CounterArea, TimelineArea, AboutArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "About",
        pageTitle: "about us"
      }
    }
  }
}
</script>

<style scoped>

</style>