<template>
  <!-- Timeline Area Start -->
  <section class="ekifa-timeline-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="ui-timline-container">
            <div class="ui-timeline">
              <div class="tl-item">
                <div class="tl-body">
                  <div class="tl-entry">
                    <div class="tl-caption">
                      <p>Ekifa <br> history</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tl-item">
                <div class="tl-body">
                  <div class="tl-entry">
                    <div class="tl-time">
                      2015
                    </div>
                    <div class="tl-icon"></div>
                    <div class="tl-content">
                      <h3 class="tl-tile">Startup Consultation</h3>
                      <h4 class="tl-date">March 26</h4>
                      <p>
                        Interdum iusto pulvinar elit.accusantium debitis voluptatem dolore excepturi ducimus fugiat nulla perspiciatis quo ipsum non eligendi nisi veniam maxime in quas atque omnis iusto!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tl-item alt">
                <div class="tl-body">
                  <div class="tl-entry">
                    <div class="tl-time">
                      2016
                    </div>
                    <div class="tl-icon"></div>
                    <div class="tl-content">
                      <h3 class="tl-tile">Consulting Idea</h3>
                      <h4 class="tl-date">June 13</h4>
                      <p>
                        Interdum iusto pulvinar elit.accusantium debitis voluptatem dolore excepturi ducimus fugiat nulla perspiciatis quo ipsum non eligendi nisi veniam maxime in quas atque omnis iusto!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tl-item">
                <div class="tl-body">
                  <div class="tl-entry">
                    <div class="tl-time">
                      2017
                    </div>
                    <div class="tl-icon"></div>
                    <div class="tl-content">
                      <h3 class="tl-tile">Audit & assurance</h3>
                      <h4 class="tl-date">February 19</h4>
                      <p>
                        Interdum iusto pulvinar elit.accusantium debitis voluptatem dolore excepturi ducimus fugiat nulla perspiciatis quo ipsum non eligendi nisi veniam maxime in quas atque omnis iusto!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tl-item alt">
                <div class="tl-body">
                  <div class="tl-entry">
                    <div class="tl-time">
                      2019
                    </div>
                    <div class="tl-icon"></div>
                    <div class="tl-content">
                      <h3 class="tl-tile">Expert Team Mates</h3>
                      <h4 class="tl-date">April 04</h4>
                      <p>
                        Interdum iusto pulvinar elit.accusantium debitis voluptatem dolore excepturi ducimus fugiat nulla perspiciatis quo ipsum non eligendi nisi veniam maxime in quas atque omnis iusto!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Timeline Area End -->
</template>

<script>
export default {
  name: "TimelineArea"
}
</script>

<style scoped>

</style>